<template>
  <div class="content">
    <h2 class="title title--indent title--big title--color">Приобретайте авиабилеты со скидками</h2>
    <form @submit.prevent="sendForm">
      <div class="content__party content__party--fullstart">
        <div class="form-group form-group--stretch">
          <div class="form-control">
            <masked-input
              v-model="search"
              mask="A111-A111-A111-A111"
              class="form-control__input"
              placeholder="Введите номер карты для проверки"
            />
          </div>
          <div class="form-group__error" v-if="$v.clearSearch.$dirty && !$v.clearSearch.passwordAlpha">
            Принимаются только английские буквы алфавита и цифры
          </div>
          <div class="form-group__error" v-if="$v.clearSearch.$dirty && !$v.clearSearch.minLength">
            Номер карты должен быть равен 16 символам
          </div>
        </div>
        <button class="button button--mini" type="submit" ref="submit" :disabled="search && disabledSubmit">
          <span>Оформить билет</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate';
import {minLength, helpers} from 'vuelidate/lib/validators';
import MaskedInput from 'vue-masked-input';

const passwordAlpha = helpers.regex('enAlpha', /[a-z A-Z0-9]/i);

export default {
  mixins: [validationMixin],
  components: {MaskedInput},
  validations: {
    clearSearch: {
      minLength: minLength(19),
      passwordAlpha
    }
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    clearSearch() {
      return this.search.replaceAll('_', '');
    },
    disabledSubmit() {
      return this.search.includes('_');
    },
  },
  methods: {
    sendForm() {
      this.$v.clearSearch.$touch();
      if (this.$v.clearSearch.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        this.$refs.submit.classList.add('preload');
        this.$store.dispatch('overall/POST_VERIFY', {card_number: this.clearSearch, slug: 'nordwind'})
          .then(response => {
            this.$refs.submit.classList.remove('preload');
            window.location = response.data.link;
          })
          .catch(error => {
            this.$refs.submit.classList.remove('preload');
            this.$notify({
              type: 'error',
              title: 'Ошибка',
              text: error.response.data.message
            });
            this.search = '';
          });
      }
    },
  }
};
</script>